import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import r1 from "../../Assets/3.jpg";
import r2 from "../../Assets/4.jpg";
import { pankcake } from "../../links";
import { ImPointRight } from "react-icons/im";

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="about-section">
        <Particle />
        <Container>
          <Row style={{ justifyContent: "center", padding: "10px", alignItems: "center" }}>
            <Col>
              <img src={r1} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $HotWB NOW
                </a>
              </button>
            </Col>
            <Col
              md={7}
              style={{
                justifyContent: "center",
                paddingTop: "30px",
                paddingBottom: "50px",
              }}
            >
              <h1 className="project-heading">
                BUY ON <strong className="purple"> UNISWAP </strong>
              </h1>
              <p style={{ color: "white" }}>
                HOW TO BUY ?
              </p>

              <h1 className="project-heading2">
                MAKE<strong className="purple">  WALLET</strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />Download metamask or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to metamask.io.
                <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                GET SOME<strong className="purple"> ETH </strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight />Have ETH in your wallet to switch to $HotWB. If you don’t have any ETH, you can buy directly on metamask, transfer from another wallet, or buy on another exchange and send it to your wallet. <strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                GO TO <strong className="purple"> UNISWAP </strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight /> Connect to Uniswap. Go to app.uniswap.org in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $HotWB token address into Uniswap, select $HotWB, and confirm. When Metamask prompts you for a wallet signature, sign.<strong className="purple"> </strong>
              </h1>
              <h1 className="project-heading2">
                Switch ETH for<strong className="purple"> $HotWB </strong>
              </h1>
              <h1 style={{ fontSize: "1.2em", paddingBottom: "20px" }}>
                <ImPointRight /> Switch ETH for $HotWB. We have low taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility.
                <strong className="purple"> </strong>
              </h1>
            </Col>
            <Col>
              <img src={r2} alt="about" className="img-fluid homeLogo" aria-labelledby="wave" />

              <button className="playButton">
                <a
                  href={pankcake}
                  target="_blank"
                  rel="noreferrer"
                >
                  BUY $HotWB NOW
                </a>
              </button>
            </Col>



          </Row>
          {/* <h1 className="project-heading">
            All <strong className="purple"> Listing</strong>
          </h1> */}

          {/* <Techstack /> */}

          {/* <h1 className="project-heading">
          <strong className="purple">Tools</strong> I use
        </h1>
        <Toolstack />

        <Github /> */}
        </Container>
      </Container>
    </div>
  );
}

export default ResumeNew;
