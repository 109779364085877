export const telegram = "https://t.me/"
export const twitter = "https://twitter.com/"
export const poo = "https://www.dextools.io/app/ether/pair-explorer/"
export const pankcake = "https://app.uniswap.org/#/swap?outputCurrency="


// echo "# pepetok" >> README.md
// git init
// git add README.md
// git commit -m "first commit"
// git branch -M main
// git remote add origin https://github.com/m0nithin/pepetok.git
// git push -u origin main